<template>
  <div>
    <dialog-form-list
      width="800px"
      :config="config"
      :formData="formData"
      @cancel="cancel"
      @confirm="confirm"
    >
      <!-- 规格 -->
      <template v-slot:specifications>
        <div style="position: relative">
          <el-input
            v-model="formData.specifications"
            :placeholder="$t('base.enter')"
          >
            <el-button
              class="selectEquipmentButton"
              v-if="cmd !== 'view'"
              slot="append"
              icon="el-icon-plus"
              @click="addCustomFiled"
            />
          </el-input>
        </div>
      </template>
      <!-- 自定义字段 -->
      <template v-slot:customFieldJson>
        <div class="lists_mk">
          <el-row :gutter="10">
            <el-col
              :span="12"
              v-for="(item, index) in customFieldJson"
              :key="index"
            >
              <div class="listItem_mk">
                <div>
                  <el-input
                    class="label_mk"
                    v-model="item.label"
                    :placeholder="$t('equipmentManage.enterTitle')"
                  />
                  <span>：</span>
                </div>
                <div>
                  <el-input
                    class="content_mk"
                    v-model="item.value"
                    :placeholder="$t('equipmentManage.enterContent')"
                  />
                </div>
                <div class="icon_mk" @click="delCustomFiled(index)">
                  <i class="el-icon-circle-close"></i>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </template>
    </dialog-form-list>
  </div>
</template>

<script>
export default {
  props: {
    // 当前选中的表格行数据
    tableRow: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      cmd: this.$store.state.cmd,
      formData: {
        scope: 'CHANNEL_LINE',
      },
      config: [
        // lang:设备名称
        {
          label: this.$t('equipmentManage.equipmentName'),
          prop: 'name',
          span: 12,
          rules: {
            noNull: true,
          },
          tagProps: {
            maxlength: 20,
            showWordLimit: true,
          },
        },
        // lang:所属分组
        {
          tag: 'el-cascader',
          label: this.$t('equipmentManage.equipmentGroup'),
          prop: 'equipmentGroup',
          span: 12,
          rules: {
            noNull: true,
          },
          tagProps: {
            options: [],
            props: {
              label: 'name',
              value: 'id',
            },
          },
        },
        // lang: 所属部门
        {
          tag: 'el-cascader',
          label: this.$t('equipmentManage.equipmentUserAccountGroup'),
          prop: 'userAccountGroup',
          span: 12,
          rules: {
            noNull: true,
          },
          tagProps: {
            options: [],
            props: {
              label: 'name',
              value: 'id',
            },
          },
        },
        // lang:可见范围
        {
          tag: 'el-select',
          label: this.$t('equipmentManage.scope'),
          prop: 'scope',
          span: 12,
          rules: {
            noNull: true,
          },
          tagProps: {
            options: this.$dict.scopeEnum(),
            disabled: true,
          },
        },
        // lang:出厂编号
        {
          label: this.$t('equipmentManage.equipmentNumber'),
          prop: 'number',
          span: 12,
          rules: {
            noNull: true,
          },
          tagProps: {
            maxlength: 20,
            showWordLimit: true,
          },
        },
        // lang:设备型号
        {
          label: this.$t('equipmentManage.equipmentModel'),
          prop: 'model',
          span: 12,
          rules: {
            noNull: true,
          },
          tagProps: {
            maxlength: 20,
            showWordLimit: true,
          },
        },
        // lang:设备规格
        {
          tag: 'slot-content',
          label: this.$t('equipmentManage.equipmentSpecifications'),
          prop: 'specifications',
          span: 24,
        },
        // lang:自定义字段
        {
          tag: 'slot',
          prop: 'customFieldJson',
          span: 24,
        },
        // lang:设备描述
        {
          tag: 'tinymce-text',
          label: this.$t('equipmentManage.equipmentDetail'),
          prop: 'detail',
          span: 24,
          tagProps: {
            height: 150,
          },
        },
        // lang:设备图片
        {
          tag: 'upload-image',
          label:
            this.$t('equipmentManage.equipmentImageSet') +
            `(${this.$t('base.countLimit') + 5 + this.$t('base.count')})`,
          prop: 'imageSet',
          span: 24,
          tagProps: {
            limit: 5,
          },
        },
        // lang:相关附件
        {
          tag: 'upload-file',
          label: this.$t('equipmentManage.equipmentFileSet'),
          prop: 'fileSet',
          span: 24,
        },
      ],
      // 自定义字段
      customFieldJson: [],
    }
  },
  mounted() {
    this.setDefaultValue()
    this.userAccountGroupFindTree()
    // 查询所属分组
    this.equipmentGroupFind()
  },
  methods: {
    // 添加自定义字段
    addCustomFiled() {
      this.customFieldJson.push({
        label: '',
        value: '',
      })
    },
    // 删除自定义字段
    delCustomFiled(index) {
      this.customFieldJson.splice(index, 1)
    },
    // 查询部门
    userAccountGroupFindTree() {
      this.$ajax({
        url: this.$api.userAccountGroupFindTree,
        data: {
          scopeCompanyId: this.$store.getters.company.id,
          companyId: this.$store.getters.company.id,
        },
      }).then((data) => {
        this.config[2].tagProps.options = data
      })
    },
    // 查询所属分组
    equipmentGroupFind() {
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.equipmentGroupFind,
        data: {
          scopeCompanyId: this.$store.getters.company.id,
          theCompanyId: this.$store.getters.company.id,
        },
      }).then((data) => {
        this.config[1].tagProps.options = data
      })
    },
    // 设置默认值
    setDefaultValue() {
      this.$nextTick(() => {
        //默认值
        if (this.cmd !== 'add') {
          this.$util.setDefaultValue(this.formData, this.tableRow)
          // 处理所属部门
          this.formData.userAccountGroup = this.$util.getTreeIds(
            this.tableRow.userAccountGroup
          )
          // 所属分组
          this.formData.equipmentGroup = this.$util.getTreeIds(
            this.tableRow.equipmentGroup
          )
          // 处理自定义字段
          if (this.tableRow.customFieldJson) {
            this.customFieldJson = JSON.parse(this.tableRow.customFieldJson)
          }
        }
      })
    },
    // 取消
    cancel() {
      this.$emit('closePage', 'cancel')
    },
    // 确定
    confirm() {
      const formData = this.$util.copyData(this.formData)
      // 处理所属部门
      formData.userAccountGroup = {
        id: formData.userAccountGroup.pop(),
      }
      // 处理所属分组
      formData.equipmentGroup = {
        id: formData.equipmentGroup.pop(),
      }
      // 处理自定义字段
      formData.customFieldJson = JSON.stringify(this.customFieldJson)
      switch (this.cmd) {
        case 'add':
          this.addMethod(formData)
          break
        case 'edit':
          this.editMethod(formData)
          break
        default: // do something
      }
    },
    // 新增
    addMethod(formData) {
      formData.level = 'ONE'
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.equipmentAdd,
        data: formData,
      }).then(() => {
        // lang:新增成功
        this.$element.showMsg(this.$t('base.addSuccess'), 'success')
        this.$emit('closePage', 'add')
      })
    },
    // 修改
    editMethod(formData) {
      const tableRow = this.$util.copyData(this.tableRow)
      Object.assign(tableRow, formData)
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.equipmentUpdate,
        data: tableRow,
      }).then(() => {
        // lang:修改成功
        this.$element.showMsg(this.$t('base.editSuccess'), 'success')
        this.$emit('closePage', 'edit')
      })
    },
  },
}
</script>
