<template>
  <both-colum-page
    class="equipmentManage"
    :title="$t('equipmentManage.title_platform')"
    right="200px"
    :formData="formData"
    :config="config"
    @search="search"
  >
    <div slot="left-title">
      <span>{{ $t('equipmentManage.groupTitle_platform') }}</span>
      <el-button
        v-if="$page.showBtn('SCOPE_EQUIPMENT_GROUP_MANAGE_ADD')"
        style="position: absolute; top: 10px; right: 10px; font-size: 12px"
        icon="el-icon-plus"
        size="mini"
        @click="addFirstGroup"
      >
        {{ $t('equipmentManage.addFirstGroup') }}
      </el-button>
    </div>
    <base-tree
      slot="left"
      ref="baseTree"
      :url="'/' + $util.getLocalStorage('apiprefix') + $api.equipmentGroupFind"
      :defaultParams="{
        scopeCompanyUid: $store.getters.company.uid,
      }"
      v-model="treeRow"
      @nodeClick="nodeClick"
    >
      <template v-slot:default="{ data }">
        <div style="display: flex; justify-content: space-between; width: 100%">
          <div>{{ data.name }}</div>
          <div
            class="groupOptions"
            v-if="data.company && data.company.id === currentCompanyId"
          >
            <i
              class="el-icon-plus"
              v-if="
                data.level !== 'FIVE' &&
                $page.showBtn('SCOPE_EQUIPMENT_GROUP_MANAGE_ADD')
              "
              @click.stop="groupMethod(data, 'add')"
            />
            <i
              v-if="$page.showBtn('SCOPE_EQUIPMENT_GROUP_MANAGE_EDIT')"
              class="el-icon-edit"
              @click.stop="groupMethod(data, 'edit')"
            />
            <i
              v-if="$page.showBtn('SCOPE_EQUIPMENT_GROUP_MANAGE_DELETE')"
              class="el-icon-delete"
              @click.stop="groupMethod(data, 'del')"
            />
          </div>
        </div>
      </template>
    </base-tree>
    <template
      v-slot:functionButton
      v-if="!treeRow || (treeRow && treeRow.company.id === currentCompanyId)"
    >
      <div class="functionButton">
        <el-button
          v-if="$page.showBtn('SCOPE_EQUIPMENT_MANAGE_ADD')"
          size="mini"
          @click="equipmentMethod('add')"
        >
          <i class="el-icon-plus" />
          {{ $t('equipmentManage.addEquipment') }}
        </el-button>
      </div>
    </template>
    <table-list
      ref="tableList"
      class="eqTableList"
      :url="'/' + $util.getLocalStorage('apiprefix') + $api.equipmentFind"
      :columns="columns"
      :defaultParams="{
        scopeCompanyUid: $store.getters.company.uid,
        needParentUserAccountGroup: 'YES',
      }"
      :treeProps="{ children: 'children' }"
      v-model="tableRow"
    >
      <!-- 所属企业 -->
      <template v-slot:company="{ row }">
        {{ row.company && row.company.name }}
      </template>
      <!-- 类型 -->
      <template v-slot:level="{ row }">
        {{ levelNames[row.level] }}
      </template>
      <!-- 所属分组 -->
      <template v-slot:equipmentGroup="{ row }">
        {{ row.equipmentGroup.name }}
      </template>
      <!-- 操作 -->
      <template v-slot:option="{ row }">
        <template v-if="row.company && row.company.id === currentCompanyId">
          <template v-if="row.level === 'ONE'">
            <el-button
              v-if="$page.showBtn('SCOPE_EQUIPMENT_MANAGE_ADD')"
              type="text"
              @click="sparePartMethod('add', row)"
              style="margin-right: 26px"
            >
              <!-- lang: 新增部件 -->
              {{ $t('equipmentManage.addPort') }}
            </el-button>
            <el-button
              v-if="$page.showBtn('SCOPE_EQUIPMENT_MANAGE_EDIT')"
              type="text"
              @click="equipmentMethod('edit', row)"
            >
              <!-- lang: 修改 -->
              {{ $t('base.edit') }}
            </el-button>
            <el-button
              v-if="$page.showBtn('SCOPE_EQUIPMENT_MANAGE_DELETE')"
              type="text"
              @click="equipmentMethod('del', row)"
            >
              <!-- lang: 删除 -->
              {{ $t('base.del') }}
            </el-button>
          </template>
          <template v-else-if="row.level === 'TWO'">
            <el-button
              v-if="$page.showBtn('SCOPE_EQUIPMENT_MANAGE_ADD')"
              type="text"
              @click="faultMethod('add', row)"
            >
              <!-- lang: 新增故障信息 -->
              {{ $t('equipmentManage.addIssue') }}
            </el-button>
            <el-button
              v-if="$page.showBtn('SCOPE_EQUIPMENT_MANAGE_EDIT')"
              type="text"
              @click="sparePartMethod('edit', row)"
            >
              <!-- lang: 修改 -->
              {{ $t('base.edit') }}
            </el-button>
            <el-button
              v-if="$page.showBtn('SCOPE_EQUIPMENT_MANAGE_DELETE')"
              type="text"
              @click="sparePartMethod('del', row)"
            >
              <!-- lang: 删除 -->
              {{ $t('base.del') }}
            </el-button>
          </template>
          <template v-else>
            <!-- lang: 修改 -->
            <el-button
              v-if="$page.showBtn('SCOPE_EQUIPMENT_MANAGE_EDIT')"
              type="text"
              @click="faultMethod('edit', row)"
              style="margin-left: 94px"
            >
              {{ $t('base.edit') }}
            </el-button>
            <!-- lang: 删除 -->
            <el-button
              v-if="$page.showBtn('SCOPE_EQUIPMENT_MANAGE_DELETE')"
              type="text"
              @click="faultMethod('del', row)"
            >
              {{ $t('base.del') }}
            </el-button>
          </template>
        </template>
      </template>
    </table-list>
    <div slot="other" class="enquFormBox scollBar">
      <div class="eqDetailList" v-if="tableRow">
        <!-- 设备简介 -->
        <show-equipment-detail
          v-if="tableRow.level === 'ONE'"
          :tableRow="tableRow"
          @view="equipmentMethod"
        />
        <!-- 部件简介 -->
        <show-spare-part-detail
          v-else-if="tableRow.level === 'TWO'"
          :tableRow="tableRow"
          @view="sparePartMethod"
        />
        <!-- 故障信息简介 -->
        <show-fault-detail v-else :tableRow="tableRow" @view="faultMethod" />
      </div>
    </div>
    <!-- 设备分组 -->
    <group-detail
      v-if="showGroupDetail"
      :treeRow="treeRow"
      :isFirstGroup="isFirstGroup"
      @closePage="closeGroupPage"
    />
    <!-- 设备 -->
    <equipment-detail
      v-if="showEquipmentDetail"
      :tableRow="tableRow"
      @closePage="closeEquipmentPage"
    />
    <!-- 部件 -->
    <spare-part-detail
      v-if="showSparePartDetail"
      :tableRow="tableRow"
      @closePage="closeSparePartPage"
    />
    <!-- 故障信息 -->
    <fault-detail
      v-if="showFaultDetail"
      :tableRow="tableRow"
      @closePage="closeFaultPage"
    />
  </both-colum-page>
</template>

<script>
import GroupDetail from './groupDetail'
import EquipmentDetail from './equipmentDetail'
import SparePartDetail from './sparePartDetail'
import FaultDetail from './faultDetail'
import ShowEquipmentDetail from './showEquipmentDetail'
import ShowSparePartDetail from './showSparePartDetail'
import ShowFaultDetail from './showFaultDetail'

export default {
  components: {
    GroupDetail,
    EquipmentDetail,
    SparePartDetail,
    FaultDetail,
    ShowEquipmentDetail,
    ShowSparePartDetail,
    ShowFaultDetail,
  },
  data() {
    return {
      currentCompanyId: this.$store.getters.company.id,
      // 设备分组
      treeRow: null,
      showGroupDetail: false,
      isFirstGroup: false,
      // 搜索
      formData: {},
      config: [
        {
          label: this.$t('base.searchStr'),
          prop: 'searchStr',
          span: 12,
          tagProps: {
            placeholder: this.$t('equipmentManage.nameOrNotes'),
          },
        },
        // lang:所属部门
        {
          tag: 'el-cascader',
          label: this.$t('adminAccount.accountGroup'),
          prop: 'userGroupId',
          span: 12,
          tagProps: {
            options: [],
            props: {
              label: 'name',
              value: 'id',
            },
          },
        },
        // {
        //   tag: 'el-select',
        //   label: this.$t('equipmentManage.scope'),
        //   prop: 'scope',
        //   span: 12,
        //   tagProps: {
        //     options: this.$dict.scopeEnum(),
        //   },
        // },
        {
          tag: 'el-date-picker',
          label: this.$t('base.createTime'),
          prop: 'createTime',
          span: 12,
          className: 'daterangeDate',
          tagProps: {
            type: 'daterange',
            startPlaceholder: this.$t('base.startDate'),
            endPlaceholder: this.$t('base.endDate'),
          },
        },
      ],
      // 表格页
      tableRow: null,
      content: [],
      levelNames: {
        ONE: this.$t('equipmentManage.equipmentInfo'),
        TWO: this.$t('equipmentManage.partInfo'),
        THREE: this.$t('equipmentManage.faultInfo'),
      },
      columns: [
        // lang:类型
        {
          label: this.$t('equipmentManage.type'),
          prop: 'level',
          width: '140px',
        },
        // lang:所属分组
        {
          label: this.$t('equipmentManage.equipmentGroup'),
          prop: 'equipmentGroup',
        },
        // lang:所属部门
        {
          label: this.$t('equipmentManage.equipmentUserAccountGroup'),
          prop: 'userAccountGroup',
          callback(row) {
            return row.userAccountGroup.name
          },
        },
        // lang:名称
        {
          label: this.$t('equipmentManage.name'),
          prop: 'name',
        },
        // lang:编号
        {
          label: this.$t('equipmentManage.number'),
          prop: 'number',
        },
        // lang:型号
        {
          label: this.$t('equipmentManage.model'),
          prop: 'model',
        },
        // lang:规格
        {
          label: this.$t('equipmentManage.specifications'),
          prop: 'specifications',
        },
        // lang:所属企业
        {
          label: this.$t('equipmentManage.company'),
          prop: 'company',
        },
        // lang:操作
        {
          label: this.$t('base.option'),
          prop: 'option',
          width: '190px',
        },
      ],
      // 设备
      showEquipmentDetail: false,
      // 部件
      showSparePartDetail: false,
      // 故障信息
      showFaultDetail: false,
    }
  },
  mounted() {
    this.userAccountGroupFindTree()
  },
  methods: {
    // 查询部门
    userAccountGroupFindTree() {
      this.$ajax({
        url: this.$api.userAccountGroupFindTree,
        data: {
          scopeCompanyUid: this.$store.getters.company.uid,
        },
      }).then((data) => {
        this.config[1].tagProps.options = data
      })
    },
    // 查询按钮点击回调
    search(isActiveQuery) {
      // 点击查询时，重置设备分组选择
      if (isActiveQuery) {
        this.$refs.baseTree.reset()
        delete this.formData.groupId
      }
      const formData = this.$util.copyData(this.formData)
      if (formData.userGroupId.length) {
        formData.userGroupId = formData.userGroupId.pop()
      } else {
        delete formData.userGroupId
      }
      this.$refs.tableList.searchList(formData, isActiveQuery)
    },
    // 选择设备分组
    nodeClick() {
      this.formData.groupId = this.treeRow.id
      this.search()
    },
    // 新增一级分组
    addFirstGroup() {
      this.isFirstGroup = true
      this.$store.commit('updateCmd', 'add')
      this.showGroupDetail = true
    },
    // 分组按钮操作方法
    groupMethod(data, method) {
      if (method === 'del') {
        this.deleteMethod('/' + this.$util.getLocalStorage('apiprefix') + this.$api.equipmentGroupDelete, data, () => {
          this.closeGroupPage('delete')
        })
        return
      }
      this.isFirstGroup = false
      this.treeRow = data
      this.$store.commit('updateCmd', method)
      if (method === 'add') {
        this.$ajax({
          url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.equipmentHasEquipment,
          data: {
            groupId: data.id,
          },
        }).then((isPass) => {
          if (isPass) {
            this.$element.showMsg(
              this.$t('equipmentManage.hasEquipment'),
              'warning'
            )
          } else {
            this.showGroupDetail = true
          }
        })
      } else {
        this.showGroupDetail = true
      }
    },
    // 删除方法
    deleteMethod(url, data, fn) {
      this.$element
        .showConfirm({
          content: this.$t('base.deleteConfirm'),
        })
        .then(() => {
          this.$ajax({
            url,
            data,
          }).then(() => {
            // lang:删除成功
            this.$element.showMsg(this.$t('base.deleteSuccess'), 'success')
            fn && fn()
          })
        })
    },
    // 关闭分组详情页回掉
    closeGroupPage(method) {
      this.showGroupDetail = false
      if (method && method !== 'cancel') {
        // 重新查询设备分组
        this.$refs.baseTree.searchList()
        // 重新查询人员
        delete this.formData.groupId
        this.search()
      }
    },
    //-----------------------------------设备---------------------------------------------
    // 关闭设备详情页回调
    closeEquipmentPage(method) {
      this.showEquipmentDetail = false
      if (method && method !== 'cancel') {
        this.search()
      }
    },
    // 设备操作
    equipmentMethod(method, row) {
      if (row) this.tableRow = row
      this.$store.commit('updateCmd', method)
      this.$store.commit(
        'updateDialogTitle',
        method === 'add'
          ? this.$t('equipmentManage.addEquipment')
          : this.$t(`base.${method}`)
      )
      switch (method) {
        case 'add':
        case 'edit':
        case 'view':
          this.showEquipmentDetail = true
          break
        case 'del':
          this.deleteMethod('/' + this.$util.getLocalStorage('apiprefix') + this.$api.equipmentDelete, this.tableRow, () => {
            this.closeEquipmentPage('delete')
          })
          break
        default: // do something
      }
    },
    //-----------------------------------部件---------------------------------------------
    // 关闭部件详情页回调 closeFaultPage
    closeSparePartPage(method) {
      this.showSparePartDetail = false
      if (method && method !== 'cancel') {
        this.search()
      }
    },
    // 部件操作
    sparePartMethod(method, row) {
      if (row) this.tableRow = row
      this.$store.commit('updateCmd', method)
      this.$store.commit(
        'updateDialogTitle',
        method === 'add'
          ? this.$t('equipmentManage.addPort')
          : this.$t(`base.${method}`)
      )
      switch (method) {
        case 'add':
        case 'edit':
        case 'view':
          this.showSparePartDetail = true
          break
        case 'del':
          this.deleteMethod('/' + this.$util.getLocalStorage('apiprefix') + this.$api.equipmentDelete, this.tableRow, () => {
            this.closeEquipmentPage('delete')
          })
          break
        default: // do something
      }
    },
    //---------------------------------故障信息-------------------------------------------
    // 关闭故障信息页回调 closeFaultPage
    closeFaultPage(method) {
      this.showFaultDetail = false
      if (method && method !== 'cancel') {
        this.search()
      }
    },
    // 故障信息操作
    faultMethod(method, row) {
      if (row) this.tableRow = row
      this.$store.commit('updateCmd', method)
      this.$store.commit(
        'updateDialogTitle',
        method === 'add'
          ? this.$t('equipmentManage.addIssue')
          : this.$t(`base.${method}`)
      )
      switch (method) {
        case 'add':
        case 'edit':
        case 'view':
          this.showFaultDetail = true
          break
        case 'del':
          this.deleteMethod('/' + this.$util.getLocalStorage('apiprefix') + this.$api.equipmentDelete, this.tableRow, () => {
            this.closeEquipmentPage('delete')
          })
          break
        default: // do something
      }
    },
  },
}
</script>

<style lang="scss">
@import './_index.scss';
</style>
