<template>
  <div>
    <dialog-form-list
      width="800px"
      :config="config"
      :formData="formData"
      @cancel="cancel"
      @confirm="confirm"
    >
      <!-- 所属备件 -->
      <template v-slot:topName>
        <div style="position: relative">
          <el-input v-model="formData.topName" :placeholder="$t('base.enter')">
            <el-button
              class="selectEquipmentButton"
              v-if="cmd !== 'view'"
              slot="append"
              icon="el-icon-plus"
              @click="addCustomFiled"
            />
          </el-input>
        </div>
      </template>
      <!-- 自定义字段 -->
      <template v-slot:customFieldJson>
        <div class="lists_mk">
          <el-row :gutter="10">
            <el-col
              :span="12"
              v-for="(item, index) in customFieldJson"
              :key="index"
            >
              <div class="listItem_mk">
                <div>
                  <el-input
                    class="label_mk"
                    v-model="item.label"
                    :placeholder="$t('equipmentManage.enterTitle')"
                  />
                  <span>：</span>
                </div>
                <div>
                  <el-input
                    class="content_mk"
                    v-model="item.value"
                    :placeholder="$t('equipmentManage.enterContent')"
                  />
                </div>
                <div class="icon_mk" @click="delCustomFiled(index)">
                  <i class="el-icon-circle-close"></i>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </template>
    </dialog-form-list>
  </div>
</template>

<script>
export default {
  props: {
    // 当前选中的表格行数据
    tableRow: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      cmd: this.$store.state.cmd,
      formData: {},
      config: [
        // lang:故障名称
        {
          label: this.$t('equipmentManage.faultName'),
          prop: 'name',
          span: 12,
          rules: {
            noNull: true,
          },
          tagProps: {
            maxlength: 20,
            showWordLimit: true,
          },
        },
        // lang:故障代码
        {
          label: this.$t('equipmentManage.faultNumber'),
          prop: 'number',
          span: 12,
          rules: {
            noNull: true,
          },
          tagProps: {
            maxlength: 20,
            showWordLimit: true,
          },
        },
        // lang:所属部件
        {
          tag: 'slot-content',
          label: this.$t('equipmentManage.faultPart'),
          prop: 'topName',
          span: 24,
          rules: {
            noNull: true,
          },
          tagProps: {
            disabled: true,
          },
        },
        // lang:自定义字段
        {
          tag: 'slot',
          prop: 'customFieldJson',
          span: 24,
        },
        // lang:故障描述
        {
          tag: 'tinymce-text',
          label: this.$t('equipmentManage.faultDetail'),
          prop: 'detail',
          span: 24,
          tagProps: {
            height: 150,
          },
        },
        // lang:解决方案
        {
          tag: 'tinymce-text',
          label: this.$t('equipmentManage.faultSolution'),
          prop: 'solution',
          span: 24,
          tagProps: {
            height: 150,
          },
        },
        // lang:故障图片
        {
          tag: 'upload-image',
          label:
            this.$t('equipmentManage.faultImageSet') +
            `(${this.$t('base.countLimit') + 5 + this.$t('base.count')})`,
          prop: 'imageSet',
          span: 24,
          rules: {
            noNull: true
          },
          tagProps: {
            limit: 5,
          },
        },
        // lang:相关附件
        {
          tag: 'upload-file',
          label: this.$t('equipmentManage.faultFileSet'),
          prop: 'fileSet',
          span: 24,
        },
      ],
      // 自定义字段
      customFieldJson: [],
    }
  },
  mounted() {
    this.setDefaultValue()
  },
  methods: {
    // 添加自定义字段
    addCustomFiled() {
      this.customFieldJson.push({
        label: '',
        value: '',
      })
    },
    // 删除自定义字段
    delCustomFiled(index) {
      this.customFieldJson.splice(index, 1)
    },
    // 设置默认值
    setDefaultValue() {
      this.$nextTick(() => {
        //默认值
        if (this.cmd !== 'add') {
          this.$util.setDefaultValue(this.formData, this.tableRow)
          this.$ajax({
            url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.getEquipmentById,
            data: this.tableRow,
          }).then((data) => {
            this.formData.topName = data.name
          })
          // 处理自定义字段
          if (this.tableRow.customFieldJson) {
            this.customFieldJson = JSON.parse(this.tableRow.customFieldJson)
          }
        } else {
          // 所属分组
          this.formData.topName = this.tableRow.name
        }
      })
    },
    // 取消
    cancel() {
      this.$emit('closePage', 'cancel')
    },
    // 确定
    confirm() {
      const formData = this.$util.copyData(this.formData)
      // 删除所属部件
      delete formData.topName
      // 处理自定义字段
      formData.customFieldJson = JSON.stringify(this.customFieldJson)
      switch (this.cmd) {
        case 'add':
          this.addMethod(formData)
          break
        case 'edit':
          this.editMethod(formData)
          break
        default: // do something
      }
    },
    // 新增
    addMethod(formData) {
      formData.level = 'THREE'
      formData.psn = this.tableRow.sn
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.equipmentAdd,
        data: formData,
      }).then(() => {
        // lang:新增成功
        this.$element.showMsg(this.$t('base.addSuccess'), 'success')
        this.$emit('closePage', 'add')
      })
    },
    // 修改
    editMethod(formData) {
      const tableRow = this.$util.copyData(this.tableRow)
      Object.assign(tableRow, formData)
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.equipmentUpdate,
        data: tableRow,
      }).then(() => {
        // lang:修改成功
        this.$element.showMsg(this.$t('base.editSuccess'), 'success')
        this.$emit('closePage', 'edit')
      })
    },
  },
}
</script>
